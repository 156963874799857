import { FormikValues } from "formik";
import UploadDniCard from "../../components/UploadDniCard/UploadDniCard";
import LivenessValidationCard from "../../components/LivenessValidationCard/LivenessValidationCard";
import {
  deleteIndividualDraft,
  getBusinessQuestions,
  getCountryList,
  getDraft,
  getDrafts,
  getJuridicalActivities,
  sendBusinessDocument,
  sendStep,
  validateAfip,
  validateRenaper,
} from "../../connectors/connectors";
import {
  emailRequired,
  cuitRequired,
  radioOrSingleSelectRequired,
  smallStringRequired,
  dniRequired,
  tramitNumberRequired,
  phone,
  largeStringRequired,
  fileRequired,
  mediumStringRequired,
  multipleSelectRequired,
  cbuRequired,
  checkboxRequired,
  checkbox,
  smallString,
  zipCodeRequired,
  noSpecialCharactersRequired,
  percentageRequired,
  fileNotRequired,
} from "../../validations";
import {
  CivilStatus,
  Step,
  Template,
  Option,
  PitbullActivities,
  PitbullCountries,
  Field,
  MidgardQuestions,
  ComponentStep,
  PersonType,
  IdentityType,
  IdentityVersion,
  GenderInitials,
  PreconfirmationStep,
  MidgardQuestion,
  StepTwoPF,
  StepThreePF,
  PitbullCountry,
  PitbullActivityJuridical,
  StepOnePJ,
  StepTwoPJ,
  StepThreePJ,
  StepEndpoint,
  CorporateType,
  CorporateTypeTranslated,
  ShareholderData,
  JuridicalShareholder,
  DocumentBusinessType,
  StepFourPJ,
  SignatoryData,
} from "../../types";
import juridicalIcon from "../../assets/images/icons/ui/juridical.svg";
import { authEmail } from "../../connectors/connectors";
import {
  capitalizeString,
  mapDocumentBusinessType,
  parseDate,
  parseGenderFromInitial,
  parseTelephone,
} from "../../app/utils";
import {
  updateSignatureRequestId,
  updateToken,
} from "../../reducers/authReducer";
import axios from "axios";
import TokenStep from "../../components/TokenStep";
import { updateRenaperData } from "../../reducers/renaperReducer";
import {
  addSendedStep,
  onUpdateCompletedStep,
  onUpdateDisabledSubStep,
  onUpdateDraftUUID,
  onUpdateFormData,
  onUpdateReachedStep,
  onUpdateStep,
  onUpdateSubStep,
} from "../../reducers/onboardingDataReducer";
import InteractiveInformation from "../../components/InteractiveInformation/InteractiveInformation";
import {
  addOneValidation,
  replaceValidations,
} from "../../reducers/validationsReducer";
import {
  asCuit,
  cityOptions,
  getCompanyData,
  getFormattedPreconfirmationFields,
  getOnboardingFields,
  getPreconfirmationStepFieldsByName,
  isCompanyWithoutShareholders,
  juridicalDocumentLinks,
  parseStateAddress,
  validBusinessDocumentationTypes,
} from "../../utils";
import { maxFileSize } from "../../utils";
import { showToastMessage } from "@almafintech/react-components";
import { genericToastifyError } from "../../utils";
import { onUpdateInfoStep } from "../../reducers/infoStepReducer";
import { deleteDoc, doc, getFirestore } from "@firebase/firestore";
import Signatories from "../../components/Signatories/Signatories";
import { confirmEmailRequired } from "../../validations";
import Shareholders from "../../components/Shareholders/Shareholders";
import moment from "moment";
import * as Sentry from "@sentry/react";
import { AppDispatch, RootState } from "../../app/store";
import { openModal } from "../../reducers/modalReducer";

const genderOptions = [
  { value: "M", label: "Masculino" },
  { value: "F", label: "Femenino" },
  { value: "X", label: "Otro" },
];

const pepOptions = [
  { value: "DIRECT", label: "Sí, directamente" },
  { value: "FAMILY", label: "Sí, por vínculo familiar" },
  { value: "NONE", label: "No" },
];

const civilStatusOptions: { value: CivilStatus; label: string }[] = [
  { value: "SINGLE", label: "Soltero/a" },
  { value: "MARRIED", label: "Casado/a" },
  { value: "WIDOWED", label: "Viudo/a" },
  { value: "DIVORCED", label: "Divorciado/a" },
];

const translateCorporate: Record<CorporateTypeTranslated, CorporateType> = {
  saCorporation: "SA - Sociedad Anónima",
  llcLimitedLiabilityCompany: "SRL - Sociedad de Responsabilidad Limitada",
  associationFoundationCooperativeChamber:
    "Asociación / Cámara / Cooperativa / Fundación / Mutual",
  trust: "Fideicomiso",
  simplifiedJointStockCompany: "SAS - Sociedad por Acciones Simplificada",
  reciprocalGuaranteeCompany: "SGR - Sociedad de Garantía Recíproca",
  consortium: "Consorcio",
  governmentAgencyOrEntity: "Organismo y/o Entidad Gubernamental",
  others: "Otros",
};

const corporateTypeOptions = Object.values(translateCorporate).map((value) => ({
  value,
  label: value,
}));

const registrationAuthorityOptions = [
  {
    label: "Inspección General de Justicia",
    value: "GENERAL_INSPECTION_OF_JUSTICE",
  },
  {
    label: "Registro Público de Comercio",
    value: "PUBLIC_REGISTRY_OF_COMMERCE",
  },
  { label: "Otro", value: "OTHER" },
];

export const emptyPhysicalShareholder = {
  personType: "PHYSICAL",
  name: "",
  lastName: "",
  citizenship: [],
  birthDate: [],
  civilStatus: [],
  address: {
    fullAddress: "",
    street: "",
    city: "",
    state: "",
    municipality: "",
    zipCode: "",
  },
  activities: [],
  taxType: "CUIT",
  taxId: "",
  ownership: "",
  foreignTaxDetail: {
    idNumber: "",
    country: "",
  },
  pep: [],
};

export const emptyJuridicalShareholder: JuridicalShareholder = {
  personType: "JURIDICAL",
  legalName: "",
  taxType: "NIF",
  taxId: "",
  ownership: "",
  foreignTaxDetail: {
    idNumber: "",
    country: "",
  },
};

// const employmentStatusOptions = [
//   {
//     value: "DEPENDENCY_RELATIONSHIP",
//     label: "Trabajo en relación de dependencia",
//   },
//   { value: "MONOTAX", label: "Soy monotributista" },
//   { value: "AUTONOMOUS", label: "Soy autónomo/a" },
//   { value: "STUDENT", label: "Soy estudiante" },
//   { value: "RETIRED", label: "Soy jubilado/a" },
//   { value: "UNEMPLOYED", label: "No tengo empleo actualmente" },
// ];

// const employmentYearsOptions = [
//   { value: "Menos de un año", label: "Menos de 1 año" },
//   { value: "1 año", label: "1 año" },
//   { value: "2 años", label: "2 años" },
//   { value: "3 años", label: "3 años" },
//   { value: "4 años", label: "4 años" },
//   { value: "5 años", label: "5 años" },
//   { value: "6 años", label: "6 años" },
//   { value: "7 años", label: "7 años" },
//   { value: "8 años", label: "8 años" },
//   { value: "9 años", label: "9 años" },
//   { value: "Más de 10 años", label: "10 años o más" },
// ];

const yesOrNoBoolOptions = [
  { value: "SI", label: "Sí" },
  { value: "NO", label: "No" },
];

const questionsToFields = (questions: MidgardQuestions) =>
  questions.map((question) => {
    const options = question.choices.map((choice) => {
      return { value: choice.option, label: choice.option };
    });

    const isRiskProfileQuestion =
      question.externalCode === "1f794542-ccb7-4128-892b-26f3a0c676e4";

    return {
      name: `investmentProfile.${question.externalCode}`,
      type: "select",
      label: question.text,
      placeholder: "Seleccioná una opción",
      validation: radioOrSingleSelectRequired(options),
      options,
      tooltip: isRiskProfileQuestion
        ? {
            label: "Ver tabla de riesgo",
            modal: "modal-risk-profile",
          }
        : undefined,
      selectType: "single",
      initialValue: "",
    };
  }) as Field[];

const getActivities = async (): Promise<PitbullActivities> => {
  try {
    const { data } = await getJuridicalActivities();
    return data;
  } catch (error) {
    throw new Error("Error al obtener las actividades");
  }
};

const getCountries = async (): Promise<PitbullCountries> => {
  try {
    const { data } = await getCountryList();
    return data;
  } catch (error) {
    throw new Error("Error al obtener los países");
  }
};

const getQuestions = async (): Promise<MidgardQuestions> => {
  try {
    const { data } = await getBusinessQuestions({
      company: getCompanyData().companyName,
    });
    return data;
  } catch (error) {
    throw new Error("Error al obtener las preguntas");
  }
};

const stepOne: Step = {
  step: 1,
  name: "Cuenta empresarial",
  titleOfStep: "Información básica",
  fields: [
    {
      initialValue: "",
      type: "cuit",
      name: "businessInformation.cuit",
      label: "CUIT de la empresa",
      placeholder: "XX-XXXXXXXX-X",
      validation: cuitRequired,
      preconfirmationLabel: "CUIT/CUIL",
      formatInPreConfirmation: (value) => (value ? asCuit(value) : "-"),
    },
    {
      initialValue: "",
      type: "email",
      name: "email",
      label: "Mail",
      validation: emailRequired,
      placeholder: "direccion@mail.com",
    },
    {
      name: "referredBool",
      type: "radio",
      label: "¿Fuiste referido?",
      validation: radioOrSingleSelectRequired(yesOrNoBoolOptions),
      initialValue: "",
      options: yesOrNoBoolOptions,
      disabledByUrlParams: "ref",
    },
    {
      conditional: {
        name: "referredBool",
        value: "SI",
      },
      initialValue: "",
      type: "text",
      name: "referrer",
      label: "Nombre o mail de quien te refirió",
      validation: smallStringRequired,
      disabledByUrlParams: "ref",
    },
  ],
  onContinue: async (values: FormikValues, dispatch) => {
    try {
      if (!dispatch || !values) throw new Error("Error: dispatch not found");

      //To prevent failed loop
      dispatch(onUpdateInfoStep(null));

      const {
        email,
        businessInformation: { cuit },
      } = values;

      if (
        cuit.startsWith("20") ||
        cuit.startsWith("23") ||
        cuit.startsWith("24") ||
        cuit.startsWith("27")
      ) {
        dispatch(onUpdateInfoStep(null));
        dispatch(onUpdateStep("information-step"));
        throw new Error("CUIT no válido");
      } else {
        const res = await authEmail({
          email,
          companyType: getCompanyData().companyName,
        });

        dispatch(updateSignatureRequestId(res.data.signatureRequestId));
      }
    } catch (error) {
      console.error(error);
      throw new Error("Error al enviar los datos");
    }
  },
};

const stepTwo: ComponentStep = {
  step: 2,
  name: "Mail",
  component: (dispatch, state, formik) => {
    if (!state || !dispatch || !formik)
      throw new Error("Error: state, dispatch or formik not found");

    const getGender = (gender: string) => {
      if (gender === "MALE") return "M";
      if (gender === "FEMALE") return "F";
      return "X";
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const completeSteps = async (steps: any, isCheckingESCO?: boolean) => {
      const isActiveAccount = isCheckingESCO; //Has information in ESCO

      //isCheckingESCO means is checking getDrafts endpoint
      const stepOne = steps.find(
        (step: StepOnePJ) => step.representativeInformation
      );
      const {
        representativeInformation: {
          birthDate,
          birthCountry,
          citizenship,
          civilStatus,
          cuit,
          gender,
          name,
          pep,
          pepDetails,
          telephone,
          lastName,
          identityDocument,
          referrer,
          addresses,
          spouse,
        },
      } = stepOne;

      const parsePep = (pep: string) => {
        if (pep === null || pep === undefined) {
          if (isActiveAccount)
            return ""; //Esco doesn't give us specific information about pep, so return empty string
          else return "NONE";
        } else return pep;
      };

      const parsedPep = parsePep(pep);

      const parsePepDetails =
        parsedPep && parsedPep !== "NONE" ? pepDetails : undefined;

      const stepOneParsed = {
        birthDate,
        birthCountry: birthCountry,
        citizenship: citizenship,
        civilStatus: civilStatus,
        cuit: cuit,
        gender: getGender(gender),
        lastName: lastName,
        name: name,
        pep: parsedPep,
        pepMotive: parsePepDetails,
        telephone: parseTelephone(telephone),
        identityDocument: identityDocument?.idNumber || cuit.slice(2, 10),
        tramitNumber: identityDocument?.procedureId,
        referredBool: referrer !== null ? true : false,
        addresses: {},
        spouseName: undefined,
        spouseLastName: undefined,
        spouseCuil: undefined,
      };
      if (addresses && addresses.length > 0) {
        stepOneParsed.addresses = {
          zipCode: addresses[0].zipCode,
          state: addresses[0].state,
          municipality: addresses[0].city,
          fullAddress: `${addresses[0].street} ${addresses[0].number} ${addresses[0].city}`,
          floor: addresses[0].floor,
          apartment: addresses[0].apartment,
          country: addresses[0].country,
        };
      }
      if (spouse) {
        stepOneParsed.spouseName = spouse.name;
        stepOneParsed.spouseLastName = spouse.lastName;
        stepOneParsed.spouseCuil = spouse.cuit;
      }
      let stepTwoParsed;

      //STEP TWO
      const stepTwo = steps.find((step: StepTwoPF) => step.employmentProfile);

      if (stepTwo) {
        const {
          employmentProfile: {
            activities,
            bankAccounts,
            employmentStatus,
            foreignTaxDetails,
            years,
          },
        } = stepTwo;
        //For the moment, the 'activities' coming from ESCO (endpoint getDrafts) are emptied because they are not parsed from the backend
        const parsedActivities =
          isActiveAccount || activities.length === 0 ? "" : activities;

        stepTwoParsed = {
          foreignTaxDetailsBool: foreignTaxDetails ? "SI" : "NO",
          bankAccountsBool:
            bankAccounts && bankAccounts.length > 0 ? "SI" : "NO",
          employmentProfile: {
            activities: parsedActivities,
            bankAccounts,
            employmentStatus,
            foreignTaxDetails: foreignTaxDetails,
            years: years,
          },
        };
      }
      let stepThreeParsed;

      //STEP THREE
      const stepThree = steps.find(
        (step: StepThreePF) => step.investmentProfile
      );

      const questions = await getQuestions();

      const answers: string[] = stepThree?.investmentProfile?.choices || [];

      const investmentProfileParsed = () => {
        const result: Record<string, string> = {};
        questions.forEach((question) => {
          const res = findAnswerAbaoutQuestion(question);
          if (res) {
            result[question.externalCode] = res.option;
          }
        });
        return result;
      };

      const findAnswerAbaoutQuestion = (question: MidgardQuestion) =>
        question.choices.find((choice) =>
          answers.find((answer) => answer === choice.externalCode)
        );

      if (stepThree) {
        stepThreeParsed = {
          investmentProfile: investmentProfileParsed(),
        };
      }

      const newValues = {
        ...stepOneParsed,
        ...stepTwoParsed,
        ...stepThreeParsed,
      };

      formik?.setValues(
        (prevValues) => ({
          ...prevValues,
          ...newValues,
        }),
        false
      );

      const stepOneToStore = {
        ...stepOne,
        personalInformation: {
          ...stepOne.personalInformation,
        },
        "@type": "1_JURIDICAL",
      };
      dispatch(addSendedStep(stepOneToStore));
      dispatch(onUpdateFormData(newValues));
      if (stepTwo) {
        const stepTwoToStore = { ...stepTwo, "@type": "2_JURIDICAL" };
        dispatch(addSendedStep(stepTwoToStore));
      }
      if (stepThree) {
        const stepThreeToStore = { ...stepThree, "@type": "3_JURIDICAL" };
        dispatch(addSendedStep(stepThreeToStore));
      }
      if (stepOne.validations)
        dispatch(replaceValidations(stepOne.validations));
      return { stepOneParsed, stepTwoParsed, stepOne, stepTwo };
    };

    const onAuthorize = async (draftUUID?: string) => {
      try {
        if (!draftUUID || !state || !formik)
          throw new Error("DraftUUID or state not found");
        const { setFieldValue, values } = formik;

        const { cuit } = values.businessInformation;

        try {
          // Check AFIP data only for autocomplete
          // Do not send in validations
          // If it fails, it will not stop the process
          const afipResponse = await validateAfip({
            cuit,
            company: getCompanyData().companyName,
          });

          if (afipResponse.data.status === "OK") {
            const legalName =
              afipResponse.data.validations[0].information.datosGenerales
                .razonSocial;

            // const externalCode = afipResponse.data.validations[0].externalCode;

            const addressAfip =
              afipResponse.data.validations[0].information.datosGenerales
                .domicilioFiscal;
            const zipCode = addressAfip.codPostal;
            const stateAddress = addressAfip.descripcionProvincia;
            const street = addressAfip.direccion;
            const municipality = addressAfip.localidad;

            setFieldValue("businessInformation.legalName", legalName);
            setFieldValue(
              "entityAddress.fullAddress",
              `${street} ${municipality}`
            );
            setFieldValue("entityAddress.street", street);
            setFieldValue("entityAddress.state", stateAddress);
            setFieldValue("entityAddress.zipCode", zipCode);
            setFieldValue("entityAddress.municipality", municipality);
            setFieldValue("entityAddress.country", "Argentina");
          }
        } catch (error) {
          console.log(error, "Error on validate AFIP");
          Sentry.captureException(error);
        }

        try {
          //Check if there is a draft with the same CUIT
          const draftsResponse = await getDrafts({
            cuit,
            personType: "JURIDICAL",
          });

          const stepsDafts = draftsResponse.data.steps;
          if (stepsDafts.length > 0) {
            // await completeSteps(stepsDafts);
            dispatch(onUpdateInfoStep("ACTIVE_ACCOUNT"));
          }
        } catch (error) {
          console.log(error, "Error on getDrafts");
          throw new Error("FAILED at validate AFIP");
        }
        if (state.infoStep.type !== "ACTIVE_ACCOUNT") {
          try {
            const draftResponse = await getDraft({
              draftUUID,
            });

            const steps = draftResponse.data.steps;

            if (steps.length > 0) {
              await completeSteps(steps);
              dispatch(onUpdateInfoStep("PENDING_REGISTRY_REMOTE"));
            }
          } catch (error) {
            console.log("Error on getDraft", error);
          }
        }
      } catch (error) {
        console.log(error, "Error on authorize");
        Sentry.captureException(error);
      }
    };

    const onSuccess = async () => {
      const { type } = state.infoStep;

      if (type !== null) {
        dispatch(onUpdateStep("information-step"));
      } else {
        dispatch(onUpdateCompletedStep(2));
        dispatch(onUpdateReachedStep(3));
        dispatch(onUpdateStep(3));
      }
    };

    return (
      <TokenStep
        previousStep={1}
        onSuccess={onSuccess}
        onAuthorize={onAuthorize}
        formik={formik}
      />
    );
  },
  disableBackNavigation: true,
};

const stepThree = (countries: PitbullCountry[]): Step => ({
  step: 3,
  name: "Identidad",
  titleOfStep: "Validación",
  subSteps: [
    {
      step: 1,
      fields: [
        {
          name: "cuit",
          type: "cuit",
          label: "CUIT/CUIL",
          validation: cuitRequired,
          initialValue: "",
          placeholder: "XX-XXXXXXXX-X",
          onChange: async (
            _values,
            setFieldValue,
            _,
            setFieldTouched,
            element
          ) => {
            const value = element && element.target.value;
            if (value) {
              await setFieldValue("identityDocument", value.slice(2, 10));
              await setFieldValue("spouseCuil", value);
              setFieldTouched && setFieldTouched("identityDocument", true);
            }
          },
          formatInPreConfirmation: (value) => (value ? asCuit(value) : "-"),
        },
        {
          placeholder: "XX.XXX.XXX",
          initialValue: "",
          type: "dni",
          name: "identityDocument",
          label: "DNI",
          disabled: true,
          validation: dniRequired,
        },
        {
          placeholder: "XXXXXXXXXXX",
          initialValue: "",
          type: "text",
          name: "tramitNumber",
          label: "Número de trámite",
          tooltip: {
            label: "¿Dónde lo encuentro?",
            modal: "modal-tramit-number",
          },
          validation: tramitNumberRequired,
        },
        {
          name: "gender",
          type: "multiple-radio",
          placeholder: "Seleccioná una opción",
          validation: radioOrSingleSelectRequired(genderOptions),
          initialValue: "",
          options: genderOptions,
          label: "Género",
        },
      ],
      onContinue: async (values: FormikValues, dispatch, state) => {
        try {
          if (!state) throw new Error("Error reading state at stepThree");

          const { gender, tramitNumber, identityDocument } = values;
          const { data } = await validateRenaper({
            gender,
            order: tramitNumber,
            number: identityDocument,
          });

          const failedStatus = ["FAILED", "CHECK"];

          // TYPES OF STATUS "OK" | "FAILED" | "CHECK"
          if (!data || !data.status || failedStatus.includes(data.status))
            throw new Error("FAILED at validate Renaper");
          else if (data.status === "OK" && dispatch) {
            const information = data.validations[0].information;

            // Convert string to JSON
            const informationParsed = JSON.parse(information);
            dispatch(
              updateRenaperData({
                ...informationParsed,
                externalCode: data.validations[0].externalCode,
              })
            );
            dispatch(
              addOneValidation({
                name: "RENAPER",
                externalCode: data.validations[0].externalCode,
              })
            );
            const { isExternalInformation } = state.onboardingData;
            const {
              names,
              lastNames,
              cuil,
              birthdate,
              countryBirth,
              streetAddress,
              numberStreet,
              country,
              city,
              nationality,
              municipality,
            } = informationParsed;

            const parsedCountry = countries.find(({ name }) => {
              const lowerCaseName = name.toLowerCase();

              return (
                countryBirth?.toLowerCase() === lowerCaseName ||
                country?.toLowerCase() === lowerCaseName
              );
            })?.name;

            const parsedCitizenship = countries.find(({ citizenship }) => {
              const lowerCaseCitizenship = citizenship?.toLowerCase();

              return nationality?.toLowerCase() === lowerCaseCitizenship;
            })?.citizenship;

            const getAddress = () => {
              let cityParsed = city;
              let municipalityParsed = municipality;
              if (city === "SIN INFORMAR") cityParsed = "";
              if (municipality === "SIN INFORMAR") municipalityParsed = "";

              if (!isExternalInformation) {
                return {
                  birthCountry: parsedCountry,
                  representativesAddresses: {
                    fullAddress: `${streetAddress} ${numberStreet} ${cityParsed} ${municipalityParsed}`,
                    country: parsedCountry,
                  },
                };
              }
            };

            const body = {
              name: names,
              lastName: lastNames,
              birthDate: birthdate,
              citizenship: parsedCitizenship,
              identityDocument,
              ...(cuil && { cuit: cuil }),
              ...getAddress(),
            };
            dispatch(onUpdateFormData({ ...body }));
            return { newValuesToForm: body };
          }
        } catch (error) {
          console.log(error, "error en el try de renaper");
          Sentry.captureException(error);
          throw new Error("FAILED at validate Renaper2");
        }
      },
    },
    {
      step: 2,
      label: "uploadDNI",
      subtitle: "Cargá fotos del frente y dorso de tu DNI.",
      component: (dispatch, state, formik) => {
        return (
          <UploadDniCard dispatch={dispatch} state={state} formik={formik} />
        );
      },
      insideForm: true,
    },
    {
      step: 3,
      component: (dispatch, state, formik) => {
        return (
          <LivenessValidationCard
            dispatch={dispatch}
            state={state}
            formik={formik}
          />
        );
      },
      insideForm: true,
    },
  ],
  disableBackNavigation: true,
});

const stepFour = (
  countriesOptions: Option[],
  activitiesOptions: Option[]
): Step => ({
  step: 4,
  name: "Datos del representante",
  titleOfStep: "Representante",
  disableButtonBack: true,
  subSteps: [
    {
      step: 1,
      fields: [
        {
          name: "name",
          type: "text",
          label: "Nombre/s",
          validation: smallStringRequired,
          initialValue: "",
          columns: 2,
          disabled: true,
        },
        {
          name: "lastName",
          type: "text",
          label: "Apellido/s",
          validation: smallStringRequired,
          initialValue: "",
          columns: 2,
          disabled: true,
          formatInPreConfirmation: (value) =>
            value ? capitalizeString(value) : "-",
        },
        {
          name: "citizenship",
          type: "text",
          label: "Nacionalidad",
          validation: smallStringRequired,
          initialValue: "",
          columns: 2,
          disabled: true,
          formatInPreConfirmation: (value) =>
            value ? capitalizeString(value) : "-",
        },
        {
          name: "birthDate",
          type: "text",
          label: "Fecha de nacimiento",
          validation: smallStringRequired,
          initialValue: "",
          columns: 2,
          disabled: true,
        },
        {
          name: "civilStatus",
          type: "select",
          label: "Estado civil",
          placeholder: "Seleccioná una opción",
          validation: radioOrSingleSelectRequired(civilStatusOptions),
          options: civilStatusOptions,
          selectType: "single",
          initialValue: "",
        },
        {
          name: "spouseName",
          type: "text",
          label: "Nombre/s del cónyuge",
          validation: smallStringRequired,
          initialValue: "",
          columns: 2,
          conditional: {
            name: "civilStatus",
            value: "MARRIED",
          },
        },
        {
          name: "spouseLastName",
          type: "text",
          label: "Apellido/s del cónyuge",
          validation: smallStringRequired,
          initialValue: "",
          columns: 2,
          conditional: {
            name: "civilStatus",
            value: "MARRIED",
          },
        },
        {
          name: "spouseCuil",
          type: "cuit",
          label: "CUIL del cónyuge",
          validation: cuitRequired,
          initialValue: "",
          placeholder: "XX-XXXXXXXX-X",
          conditional: {
            name: "civilStatus",
            value: "MARRIED",
          },
          onBlur: async (values, _, setFieldError) => {
            const value = values.spouseCuil;
            const cuit = values.cuit;

            if (cuit === value && setFieldError) {
              setFieldError("spouseCuil", "Debe ser distinto al tuyo.");
            }
          },
          onChange: async (
            _values,
            setFieldValue,
            _,
            setFieldTouched,
            element
          ) => {
            //If the spouse CUIL value is the same as the user's CUIT, sets an error on the spouse CUIL field.
            const value = element && element.target.value;
            await setFieldValue("spouseCuil", value);
            setFieldTouched && setFieldTouched("spouseCuil", true);
          },
          formatInPreConfirmation: (value) => (value ? asCuit(value) : "-"),
        },
        {
          name: "telephone",
          type: "phone",
          label: "Teléfono celular",
          validation: phone,
          initialValue: "",
          infoText: "Con código de área sin 0 ni 15.",
          formatInPreConfirmation: (value) =>
            value.startsWith("+") ? value : `+${value}`,
        },
        {
          name: "activities",
          type: "select",
          label: "Rubro o actividad",
          placeholder: "Seleccioná las actividades que correspondan",
          validation: multipleSelectRequired(activitiesOptions),
          options: activitiesOptions,
          selectType: "multiple-checkbox",
          initialValue: "",
          showExternalBox: true,
        },
        {
          name: "pep",
          type: "select",
          label: "¿Estás expuesto/a políticamente?",
          tooltip: {
            label: "¿Qué significa?",
            modal: "modal-PEP",
          },
          placeholder: "Seleccioná una opción",
          validation: radioOrSingleSelectRequired(pepOptions),
          options: pepOptions,
          selectType: "single",
          initialValue: "",
          preconfirmationLabel: "¿Expuesto/a políticamente?",
        },
        {
          conditional: {
            name: "pep",
            value: ["DIRECT", "FAMILY"],
          },
          name: "pepMotive",
          type: "textarea",
          label: "Describí brevemente la situación",
          validation: largeStringRequired,
          initialValue: "",
        },
      ],
    },
    {
      step: 2,
      fields: [
        {
          name: "representativesAddresses.country",
          type: "select",
          label: "País de residencia",
          placeholder: "Seleccioná una opción",
          validation: radioOrSingleSelectRequired(countriesOptions),
          options: countriesOptions,
          selectType: "single",
          initialValue: "",
          onChange: (_, setFieldValue) => {
            const fieldsToClear = {
              "representativesAddresses.fullAddress": "",
              "representativesAddresses.municipality": "",
              "addresses.stateSelect": "",
              "addresses.stateText": "",
              "representativesAddresses.state": "",
              "representativesAddresses.zipCode": "",
              "representativesAddresses.floor": "",
              "representativesAddresses.apartment": "",
            };

            Object.entries(fieldsToClear).forEach(([field, value]) => {
              setFieldValue(field, value);
            });
          },
        },
        {
          name: "representativesAddresses.fullAddress",
          type: "address",
          label: "Domicilio",
          validation: mediumStringRequired,
          initialValue: "",
          autoSelect: "representativesAddresses.fullAddress",
          country: "representativesAddresses.country",
          onChange: (values, setFieldValue) => {
            const zipCode = values.formattedAddress.postalCode;
            const state = values.formattedAddress.state;
            const city = values.formattedAddress.city || "";
            const municipality = values.formattedAddress.municipality;
            const street = values.formattedAddress.street;
            const number = values.formattedAddress.streetNumber;

            const fieldStateAddresses =
              values.formattedAddress.country === "Argentina"
                ? "representativesAddresses.stateSelect"
                : "representativesAddresses.stateText";

            const parsedStateAddress = parseStateAddress(state);

            setFieldValue("representativesAddresses.zipCode", zipCode);
            setFieldValue(fieldStateAddresses, parsedStateAddress);
            setFieldValue("representativesAddresses.state", parsedStateAddress);
            setFieldValue("representativesAddresses.city", city);
            setFieldValue(
              "representativesAddresses.municipality",
              municipality
            );
            setFieldValue("representativesAddresses.street", street);
            setFieldValue("representativesAddresses.number", number);
          },
          autoComplete: "off",
          formatInPreConfirmation: (value) =>
            value ? value?.split(",")?.[0] : "-", // Only show street
        },
        {
          name: "representativesAddresses.floor",
          type: "text",
          label: "Piso",
          validation: smallString,
          initialValue: "",
          columns: 3,
          maxLength: 10,
        },
        {
          name: "representativesAddresses.apartment",
          type: "text",
          label: "Departamento",
          validation: smallString,
          initialValue: "",
          columns: 3,
          maxLength: 10,
        },
        {
          name: "representativesAddresses.zipCode",
          type: "text",
          label: "Código postal",
          validation: zipCodeRequired,
          initialValue: "",
          columns: 3,
        },
        {
          name: "representativesAddresses.municipality",
          type: "text",
          label: "Localidad",
          validation: smallStringRequired,
          initialValue: "",
          columns: 2,
        },
        {
          name: "representativesAddresses.stateSelect",
          type: "select",
          label: "Provincia",
          validation: radioOrSingleSelectRequired(cityOptions()),
          initialValue: "",
          options: cityOptions(),
          columns: 2,
          onChange: (value, setFieldValue) => {
            setFieldValue("representativesAddresses.state", value);
          },
          conditional: {
            name: "representativesAddresses.country",
            value: "Argentina",
          },
        },
        {
          name: "representativesAddresses.stateText",
          type: "text",
          label: "Provincia",
          validation: smallStringRequired,
          initialValue: "",
          columns: 2,
          onChange: (value, setFieldValue) => {
            setFieldValue("representativesAddresses.state", value);
          },
          conditional: {
            name: "representativesAddresses.country",
            value: countriesOptions
              .map((country) =>
                country.value.toLowerCase() !== "argentina"
                  ? country.value
                  : null
              )
              .filter((value): value is string => value !== null),
          },
        },
      ],
      onContinue: async (values: FormikValues, dispatch, state) => {
        if (!state || !state.validations || !dispatch)
          throw new Error("Error: state or dispatch not found");
        try {
          const {
            email,
            name,
            lastName,
            gender,
            cuit,
            identityDocument,
            tramitNumber,
            citizenship,
            civilStatus,
            telephone,
            birthDate,
            birthCountry,
            pep,
            representativesAddresses: {
              floor,
              apartment,
              state: stateAddress,
              city,
              street,
              number,
              municipality,
              country,
              zipCode,
            },
            referredBool,
            spouseCuil,
            spouseLastName,
            spouseName,
            referrer,
            activities,
          } = values;

          const stepOne = state.onboardingData?.sendedSteps?.find(
            (step) => "representativeInformation" in step
          ) as StepOnePJ;

          const versionOfIdentity =
            state.renaper.copy ||
            stepOne.representativeInformation.identityDocument.version;

          const creationDate = state.renaper.creationDate
            ? parseDate(state.renaper.creationDate)
            : stepOne.representativeInformation.identityDocument.creationDate;

          const expirationDate = state.renaper.expirationDate
            ? parseDate(state.renaper.expirationDate)
            : stepOne.representativeInformation.identityDocument.expirationDate;
          const step = {
            "@type": "1_JURIDICAL",
            representativeInformation: {
              name,
              lastName,
              cuit,
              email,
              gender: parseGenderFromInitial[gender as GenderInitials],
              identityDocument: {
                type: "DNI" as IdentityType,
                idNumber: identityDocument,
                procedureId: tramitNumber,
                version: versionOfIdentity as IdentityVersion,
                creationDate,
                expirationDate,
              },
              telephone: `+${telephone}`,
              citizenship,
              birthCountry,
              birthDate: parseDate(birthDate),
              civilStatus,
              pep,
              pepDetails: pep === "NONE" ? undefined : values.pepMotive,
              addresses: [
                {
                  street,
                  number,
                  zipCode,
                  city,
                  state: parseStateAddress(stateAddress) || "",
                  country,
                  floor,
                  apartment,
                  municipality,
                },
              ],
              activities: activities,

              spouse:
                civilStatus === "MARRIED"
                  ? {
                      cuit: spouseCuil,
                      lastName: spouseLastName,
                      name: spouseName,
                    }
                  : undefined,
            },
            validations: state.validations,
            referrer: referredBool ? referrer : undefined,
          };

          const body = {
            companyType: getCompanyData().companyName,
            personType: state.activeTemplate.onboardingType as PersonType,
            email,
            steps: [step],
          };
          await sendStep({ stepId: 1, body });
          dispatch(addSendedStep(step));
        } catch (error) {
          console.log("Error step four: ", error);
          throw new Error("Error al enviar los datos en el paso 4");
        }
      },
    },
  ],
});

const stepFive = (
  countriesOptions: Option[],
  activitiesOptions: Option[]
): Step => ({
  step: 5,
  name: "Datos de la entidad",
  titleOfStep: "Entidad",
  subSteps: [
    {
      step: 1,
      fields: [
        {
          name: "businessInformation.legalName",
          type: "text",
          label: "Razón social",
          validation: smallStringRequired,
          initialValue: "",
          disabled: (state) => {
            const { formValues } = state.onboardingData;
            return !!formValues?.businessInformation?.legalName;
          },
        },
        {
          name: "entityAddress.fullAddress",
          type: "address",
          label: "Domicilio",
          validation: mediumStringRequired,
          initialValue: "",
          autoSelect: "entityAddress.fullAddress",
          country: "entityAddress.country",
          onChange: (values, setFieldValue) => {
            const zipCode = values.formattedAddress.postalCode;
            const state = values.formattedAddress.state;
            const city = values.formattedAddress.city;
            const municipality = values.formattedAddress.municipality;
            const street = values.formattedAddress.street;
            const number = values.formattedAddress.streetNumber;

            const parsedStateAddress = parseStateAddress(state);

            setFieldValue("entityAddress.zipCode", zipCode);
            setFieldValue("entityAddress.state", parsedStateAddress);
            setFieldValue("entityAddress.city", city);
            setFieldValue("entityAddress.municipality", municipality);
            setFieldValue("entityAddress.street", street);
            setFieldValue("entityAddress.number", number);
          },
          autoComplete: "off",
          formatInPreConfirmation: (value) => value?.split(",")?.[0], // Only show street
        },
        {
          name: "entityAddress.floor",
          type: "text",
          label: "Piso",
          validation: smallString,
          initialValue: "",
          columns: 3,
        },
        {
          name: "entityAddress.apartment",
          type: "text",
          label: "Departamento",
          validation: smallString,
          initialValue: "",
          columns: 3,
        },
        {
          name: "entityAddress.zipCode",
          type: "text",
          label: "Código postal",
          validation: zipCodeRequired,
          initialValue: "",
          columns: 3,
        },
        {
          name: "entityAddress.municipality",
          type: "text",
          label: "Localidad",
          validation: smallStringRequired,
          initialValue: "",
          columns: 2,
        },
        {
          name: "entityAddress.state",
          type: "select",
          label: "Provincia",
          validation: radioOrSingleSelectRequired(cityOptions()),
          initialValue: "",
          options: cityOptions(),
          columns: 2,
        },
        {
          name: "businessInformation.corporateType",
          type: "select",
          label: "Tipo de sociedad",
          placeholder: "Seleccioná la sociedad que corresponda",
          validation: radioOrSingleSelectRequired(corporateTypeOptions),
          options: corporateTypeOptions,
          selectType: "single",
          initialValue: "",
        },
        {
          name: "businessInformation.activities",
          type: "select",
          label: "Rubro o actividad",
          placeholder: "Seleccioná las actividades que correspondan",
          validation: multipleSelectRequired(activitiesOptions),
          options: activitiesOptions,
          selectType: "multiple-checkbox",
          initialValue: "",
          showExternalBox: true,
        },
        {
          name: "businessInformation.registrationCountry",
          type: "select",
          label: "País de registro",
          placeholder: "Seleccioná una opción",
          validation: radioOrSingleSelectRequired(countriesOptions),
          options: countriesOptions,
          selectType: "single",
          initialValue: "",
        },
        {
          name: "businessInformation.registrationProvinceTextSelect",
          type: "select",
          label: "Provincia de constitución",
          validation: radioOrSingleSelectRequired(cityOptions()),
          initialValue: "",
          options: cityOptions(),
          dontScrollTo: true,
          conditional: {
            name: "businessInformation.registrationCountry",
            value: "Argentina",
          },
        },
        {
          name: "businessInformation.registrationProvinceText",
          type: "text",
          label: "Provincia de constitución",
          validation: smallStringRequired,
          initialValue: "",
          dontScrollTo: true,
          conditional: {
            name: "businessInformation.registrationCountry",
            value: countriesOptions
              .map((country) =>
                country.value.toLowerCase() !== "argentina"
                  ? country.value
                  : null
              )
              .filter((value): value is string => value !== null),
          },
        },
        {
          name: "businessInformation.registrationDate",
          type: "select",
          label: "Fecha de registro",
          options: [],
          selectType: "single-radio-date-picker-day",
          minDatePickerDate: moment().subtract(100, "years").toDate(),
          maxDatePickerDate: moment().toDate(),
          defaultSelectedKeys: "date",
          validation: smallStringRequired,
          initialValue: "",
          formatInPreConfirmation: (value) =>
            moment(value).format("DD/MM/YYYY"),
        },
        {
          name: "businessInformation.registrationNumber",
          type: "text",
          label: "Número de inscripción",
          validation: smallStringRequired,
          initialValue: "",
          tooltip: {
            label: "¿Dónde lo encuentro?",
            content:
              "El número de inscripción de la empresa figura en el estatuto.",
          },
        },
        {
          name: "businessInformation.registrationAuthority",
          type: "select",
          label: "Organismo de inscripción",
          placeholder: "Seleccioná una opción",
          validation: radioOrSingleSelectRequired(registrationAuthorityOptions),
          options: registrationAuthorityOptions,
          selectType: "single",
          initialValue: "",
          tooltip: {
            content: "El organismo bajo el cual registraste la sociedad.",
          },
        },
      ],
    },
    {
      step: 2,
      fields: [
        {
          name: "businessInformation.hasJointSignature",
          type: "radio",
          label: "¿Tiene firma conjunta?",
          validation: radioOrSingleSelectRequired(yesOrNoBoolOptions),
          initialValue: "",
          options: yesOrNoBoolOptions,
          tooltip: {
            content:
              "La firma conjunta exige el acuerdo de distintos titulares presentes en el estatuto para realizar operaciones.",
          },
          preconfirmationLabel: "Firma conjunta",
        },
        {
          name: "businessInformation.isPublicEntity",
          type: "radio",
          label: "¿Es una entidad pública?",
          validation: radioOrSingleSelectRequired(yesOrNoBoolOptions),
          initialValue: "",
          options: yesOrNoBoolOptions,
          tooltip: {
            content: "Posee una participación estatal del 20% o más.",
          },
          preconfirmationLabel: "Entidad pública",
        },
        {
          name: "businessInformation.isInEconomicGroup",
          type: "radio",
          label: "¿Es parte de un grupo económico?",
          validation: radioOrSingleSelectRequired(yesOrNoBoolOptions),
          initialValue: "",
          options: yesOrNoBoolOptions,
          tooltip: {
            content:
              "Un grupo económico se conforma por dos o más empresas vinculadas por una relación de control o pertenencia a una misma organización.",
          },
          preconfirmationLabel: "Grupo económico",
        },
        {
          name: "businessInformation.isComplexStructure",
          type: "radio",
          label: "¿Tiene una estructura societaria compleja?",
          validation: radioOrSingleSelectRequired(yesOrNoBoolOptions),
          initialValue: "",
          options: yesOrNoBoolOptions,
          tooltip: {
            content:
              "Una estructura compleja se da cuando la sociedad está compuesta por muchas personas y/o sociedades.",
          },
        },
        {
          name: "businessInformation.bankAccountBool",
          type: "radio",
          label: "¿Tiene cuenta bancaria en Argentina?",
          validation: radioOrSingleSelectRequired(yesOrNoBoolOptions),
          initialValue: "",
          options: yesOrNoBoolOptions,
        },
        {
          conditional: {
            name: "businessInformation.bankAccountBool",
            value: "SI",
          },
          name: "businessInformation.bankAccounts",
          type: "text",
          label: "CBU / CVU",
          initialValue: "",
          validation: cbuRequired,
          preconfirmationLabel: "Cuenta bancaria argentina",
        },
      ],
      onContinue: async (values: FormikValues, dispatch, state) => {
        if (!state || !state.validations || !dispatch)
          throw new Error("Error: state or dispatch not found");
        try {
          const {
            email,
            businessInformation: {
              cuit,
              legalName,
              corporateType,
              activities,
              registrationNumber,
              registrationAuthority,
              registrationCountry,
              registrationDate,
              registrationProvinceText,
              registrationProvinceTextSelect,
              isPublicEntity,
              hasJointSignature,
              isInEconomicGroup,
              isComplexStructure,
              bankAccountBool,
              bankAccount,
            },
            entityAddress: {
              apartment,
              city,
              floor,
              municipality,
              number,
              state: stateAddress,
              street,
              zipCode,
            },
          } = values;

          const parseStringToBoolean = (string: "SI" | "NO") => {
            if (string === "SI") return true;
            else return false;
          };
          const getBankAccounts = () => {
            return bankAccountBool === "SI" ? [{ cxu: bankAccount }] : [];
          };

          const registrationProvince =
            registrationProvinceText || registrationProvinceTextSelect;
          const parsedRegistrationProvince =
            String(registrationProvince).toUpperCase();

          //We create this variable because the sentSteps, when updated when step 2 is sent and we need to send step 3, does not update the store correctly
          let stepsSended = [];

          const step: StepTwoPJ = {
            "@type": "2_JURIDICAL",
            businessInformation: {
              cuit,
              legalName,
              corporateType,
              activities,
              registrationNumber,
              registrationAuthority,
              registrationCountry,
              registrationDate,
              registrationProvince: parsedRegistrationProvince,
              isPublicEntity: parseStringToBoolean(isPublicEntity),
              hasJointSignature: parseStringToBoolean(hasJointSignature),
              isInEconomicGroup: parseStringToBoolean(isInEconomicGroup),
              isComplexStructure: parseStringToBoolean(isComplexStructure),
              addresses: [
                {
                  street,
                  number,
                  zipCode,
                  city,
                  state: stateAddress,
                  country: "Argentina",
                  floor,
                  apartment,
                  municipality,
                },
              ],
              bankAccounts: [...getBankAccounts()],
              // ...getForeignTaxDetails(),
            },
          };
          const { sendedSteps } = state.onboardingData;

          //Remove from sendedSteps the step with the same type. Prevents duplicates
          const filteredSendedSteps = sendedSteps.filter(
            (step: StepEndpoint) => step["@type"] !== "2_JURIDICAL"
          );
          const body = {
            companyType: getCompanyData().companyName,
            personType: state.activeTemplate.onboardingType as PersonType,
            email,
            steps: [...filteredSendedSteps, step],
          };
          await sendStep({ stepId: 2, body });
          stepsSended = [...filteredSendedSteps, step];
          dispatch(addSendedStep(step));

          if (hasJointSignature === "SI") {
            dispatch(onUpdateSubStep(3));
          } else {
            // Remove from sendedSteps the step with the same type. Prevents duplicates
            const filteredSendedSteps = stepsSended.filter(
              (step: StepEndpoint) => step["@type"] !== "3_JURIDICAL"
            );
            const step: StepThreePJ = {
              "@type": "3_JURIDICAL",
              additionalSignatories: [],
            };

            await sendStep({
              stepId: 3,
              body: {
                companyType: getCompanyData().companyName,
                personType: state.activeTemplate.onboardingType as PersonType,
                email,
                steps: [...filteredSendedSteps, step],
              },
            });
            dispatch(addSendedStep(step));

            dispatch(onUpdateCompletedStep(5));
            dispatch(onUpdateDisabledSubStep(3));
            if (isCompanyWithoutShareholders(corporateType)) {
              //Remove from sendedSteps the step with the same type. Prevents duplicates
              const filteredSendedSteps = sendedSteps.filter(
                (step: StepEndpoint) => step["@type"] !== "4_JURIDICAL"
              );

              const step: StepFourPJ = {
                "@type": "4_JURIDICAL",
                shareholders: [],
              };

              await sendStep({
                stepId: 4,
                body: {
                  companyType: getCompanyData().companyName,
                  personType: "JURIDICAL",
                  email: values.email || "",
                  steps: [...filteredSendedSteps, step],
                },
              });

              dispatch(addSendedStep(step));
              dispatch(onUpdateStep(7));
              dispatch(onUpdateCompletedStep(6));
            } else {
              dispatch(onUpdateStep(6));
            }
          }
        } catch (error) {
          console.log("Error step four: ", error);
          throw new Error("Error al enviar los datos en el paso 5");
        }
      },
    },
    {
      step: 3,
      name: "Datos de la entidad",
      subtitle: "Quiénes estarán a cargo en la plataforma.",
      component: () => <Signatories />,
      insideForm: true,
      secondaryForm: {
        collectionName: "signatories",
        fields: [
          {
            name: "name",
            type: "text",
            label: "Nombre/s",
            validation: smallStringRequired,
            initialValue: "",
            columns: 2,
          },
          {
            name: "lastName",
            type: "text",
            label: "Apellido/s",
            validation: smallStringRequired,
            initialValue: "",
            columns: 2,
          },
          {
            name: "identityDocument",
            type: "dni",
            label: "DNI",
            initialValue: "",
            validation: dniRequired,
          },
          {
            type: "email",
            name: "email",
            label: "Mail",
            validation: emailRequired,
            initialValue: "",
            onBlur: async (values, _, setFieldError, __, ___, state) => {
              if (!state) throw new Error("Error: state not found");

              const { formValues } = state.onboardingData;
              const signatories: SignatoryData[] =
                formValues?.signatories || [];
              const signatoriesEmail = signatories.map(({ email }) => email);
              const value = values.email;

              if (value && signatoriesEmail.includes(value) && setFieldError) {
                setFieldError(
                  "email",
                  "No se puede utilizar el mismo mail para más de un firmante."
                );
              }
            },
            onChange: async (
              _values,
              setFieldValue,
              _,
              setFieldTouched,
              element
            ) => {
              // To set error if the email is already in the list of signatories
              const value = element && element.target.value;
              await setFieldValue("email", value);
              setFieldTouched && setFieldTouched("email", true);
            },
          },
          {
            type: "email",
            name: "confirmEmail",
            label: "Confirmar mail",
            validation: confirmEmailRequired,
            initialValue: "",
            onBlur: async (values, _, setFieldError, __, ___, state) => {
              if (!state) throw new Error("Error: state not found");

              const { formValues } = state.onboardingData;
              const signatories: SignatoryData[] =
                formValues?.signatories || [];
              const signatoriesEmail = signatories.map(({ email }) => email);
              const value = values.email;

              if (value && signatoriesEmail.includes(value) && setFieldError) {
                setFieldError(
                  "confirmEmail",
                  "No se puede utilizar el mismo mail para más de un firmante."
                );
              }
            },
            onChange: async (
              _values,
              setFieldValue,
              _,
              setFieldTouched,
              element
            ) => {
              // To set error if the email is already in the list of signatories
              const value = element && element.target.value;
              await setFieldValue("confirmEmail", value);
              setFieldTouched && setFieldTouched("confirmEmail", true);
            },
          },
        ],
        onSave: (values, dispatch, state) => {
          if (!state || !dispatch)
            throw new Error("Error: state or dispatch not found");

          const {
            onboardingData: { formValues },
            collectionData: { activeIndex },
          } = state;

          const signatories = formValues?.signatories || [];

          const newSignatories = [...signatories];
          newSignatories[activeIndex - 1] = values;

          dispatch(onUpdateFormData({ signatories: newSignatories }));
        },
      },
    },
  ],
  lastSubstepToGoBack: (formik) => {
    const { hasJointSignature } = formik.values;
    return hasJointSignature === "SI" ? 3 : 2;
  },

  // onContinue: async (values: FormikValues, dispatch, state) => {
  //   try {
  //     const {
  //       email,
  //       foreignTaxDetailsBool,
  //       bankAccountsBool,
  //       businessInformation: {
  //         cuit,
  //         legalName,
  //         corporateType,
  //         activities,
  //         registrationNumber,
  //         registrationAuthority,
  //         registrationCountry,
  //         registrationDate,
  //         isPublicEntity,
  //         hasJointSignature,
  //         isInEconomicGroup,
  //         isComplexStructure,
  //         bankAccounts,
  //       },
  //       entityAddress: {
  //         apartment,
  //         city,
  //         floor,
  //         // fullAddress,
  //         municipality,
  //         number,
  //         state: stateAddress,
  //         street,
  //         zipCode,
  //       },
  //     } = values;

  //     if (!state || !dispatch)
  //       throw new Error("Error: state or dispatch not found");

  //     const {
  //       onboardingData: { sendedSteps },
  //     } = state;

  //     const getForeignTaxDetails = () => {
  //       return foreignTaxDetailsBool === "SI"
  //         ? {
  //             foreignTaxDetails: {
  //               country: values.businessInformation.foreignTaxDetails.country,
  //               idNumber: values.businessInformation.foreignTaxDetails.idNumber,
  //             },
  //           }
  //         : {};
  //     };

  //     const getBankAccounts = () => {
  //       return bankAccountsBool === "SI" ? [{ cxu: bankAccounts }] : [];
  //     };
  //     console.log();
  //     const step: StepTwoPJ = {
  //       "@type": "2_JURIDICAL",
  //       businessInformation: {
  //         cuit,
  //         legalName,
  //         corporateType,
  //         activities,
  //         registrationNumber,
  //         registrationAuthority,
  //         registrationCountry,
  //         registrationDate,
  //         isPublicEntity,
  //         hasJointSignature,
  //         isInEconomicGroup,
  //         isComplexStructure,
  //         addresses: [
  //           {
  //             street,
  //             number,
  //             zipCode,
  //             city,
  //             state: stateAddress,
  //             country: "Argentina",
  //             floor,
  //             apartment,
  //             municipality,
  //           },
  //         ],
  //         bankAccounts: [...getBankAccounts()],
  //         ...getForeignTaxDetails(),
  //       },
  //       validations: [],
  //     };

  //     //Remove from sendedSteps the step with the same type. Prevents duplicates
  //     const filteredSendedSteps = sendedSteps.filter(
  //       (step: StepEndpoint) => step["@type"] !== "2_JURIDICAL"
  //     );

  //     const body = {
  //       companyType: "ALLARIAMAS",
  //       personType: state.activeTemplate.onboardingType as PersonType,
  //       email,
  //       steps: [...filteredSendedSteps, step],
  //     };

  //     await sendStep({ stepId: 2, body });
  //     dispatch(addSendedStep(step));
  //   } catch (error) {
  //     console.log(error, "Error at step five");
  //     throw new Error("Error al enviar los datos en el paso 5");
  //   }
  // },
});

const stepSix = (
  countriesOptions: Option[],
  activitiesOptions: Option[],
  citizenshipOptions: Option[]
): Step => ({
  step: 6,
  name: "Accionistas",
  subtitle:
    "Cargá accionistas con más del 10%. Si todos tienen menos, ingresá al menos uno. Luego te pediremos la Composición Accionaria.",
  component: (_, __, formik) => <Shareholders formik={formik} />,
  insideForm: true,
  secondaryForm: {
    collectionName: "shareholders",
    tabs: {
      options: [
        {
          id: "PHYSICAL",
          label: "Persona física",
        },
        {
          id: "JURIDICAL",
          label: "Persona jurídica",
        },
      ],
      active: (activeShareholder: ShareholderData) =>
        activeShareholder?.personType,
      onChange: (tab, setActiveTab, dispatch, state, values) => {
        if (!state || !dispatch || !values)
          throw new Error("Error: state or dispatch not found");

        const {
          onboardingData: { formValues },
          collectionData: { activeIndex },
        } = state;

        const shareholders = formValues?.shareholders || [];

        const hasSavedData =
          shareholders[activeIndex - 1].ownership ||
          values.legalName ||
          values.name;

        // Show alert if user tries to change the tab with saved data
        if (hasSavedData) {
          dispatch(openModal({ name: "save-shareholder" }));
        } else {
          const newShareholders = [...shareholders];

          newShareholders[activeIndex - 1] =
            tab === "PHYSICAL"
              ? emptyPhysicalShareholder
              : emptyJuridicalShareholder;

          dispatch(onUpdateFormData({ shareholders: newShareholders }));
          setActiveTab(tab);
        }
      },
      disabled: (state) => {
        if (!state) throw new Error("Error: state or dispatch not found");

        const {
          onboardingData: { formValues },
          collectionData: { activeIndex },
        } = state;

        const shareholders = formValues?.shareholders || [];
        const activeShareholder = shareholders[activeIndex - 1];
        const isCompletedShareholder =
          activeShareholder && activeShareholder.ownership;

        return isCompletedShareholder;
      },
    },
    alert: {
      id: "save-shareholder",
      title: "¿Descartar accionista?",
      message: "Se descartarán los datos ingresados que no fueron guardados.",
      primaryButton: {
        text: "Sí, eliminar",
        action: (tab, dispatch, state) => {
          if (!state || !dispatch)
            throw new Error("Error: state or dispatch not found");

          const {
            onboardingData: { formValues },
            collectionData: { activeIndex },
          } = state;

          const shareholders = formValues?.shareholders || [];

          const newShareholders = [...shareholders];

          newShareholders[activeIndex - 1] =
            tab === "PHYSICAL"
              ? emptyJuridicalShareholder
              : emptyPhysicalShareholder;

          dispatch(onUpdateFormData({ shareholders: newShareholders }));
        },
      },
    },
    fields: [
      {
        type: "text",
        name: "legalName",
        label: "Razón social",
        validation: smallStringRequired,
        initialValue: "",
        conditional: {
          name: "personType",
          value: "JURIDICAL",
        },
      },
      {
        type: "text",
        name: "name",
        label: "Nombre/s",
        validation: smallStringRequired,
        initialValue: "",
        columns: 2,
        conditional: {
          name: "personType",
          value: "PHYSICAL",
        },
      },
      {
        type: "text",
        name: "lastName",
        label: "Apellido/s",
        validation: smallStringRequired,
        initialValue: "",
        columns: 2,
        conditional: {
          name: "personType",
          value: "PHYSICAL",
        },
      },
      {
        type: "number",
        name: "ownership",
        label: "Porcentaje de participación",
        validation: percentageRequired,
        isNumberPercentage: true,
        initialValue: "",
        columns: 2,
        formatInPreConfirmation: (value) => `${value}%`,
      },
      {
        type: "cuit",
        name: "taxId",
        label: "Identificación fiscal",
        validation: cuitRequired,
        initialValue: "",
        columns: 2,
        formatInPreConfirmation: (value) => (value ? asCuit(value) : "-"),
        onBlur: async (values, _, setFieldError, __, ___, state) => {
          if (!state) throw new Error("Error: state not found");

          const { formValues } = state.onboardingData;
          const shareholders: ShareholderData[] =
            formValues?.shareholders || [];

          const shareholdersTaxId = shareholders.map(
            (shareholder) => shareholder?.taxId
          );
          const value = values.taxId;

          if (value && shareholdersTaxId.includes(value) && setFieldError) {
            setFieldError(
              "taxId",
              "No se puede utilizar la misma identificación fiscal para más de un accionista"
            );
          }
        },
        onChange: async (
          _values,
          setFieldValue,
          _,
          setFieldTouched,
          element
        ) => {
          // To set error if the taxId is already in the list of shareholders
          const value = element && element.target.value;
          await setFieldValue("taxId", value);
          setFieldTouched && setFieldTouched("taxId", true);
        },
      },
      {
        name: "citizenship",
        type: "select",
        label: "Nacionalidad",
        placeholder: "Seleccioná una opción",
        options: citizenshipOptions,
        selectType: "single",
        validation: radioOrSingleSelectRequired(citizenshipOptions),
        initialValue: "",
        columns: 2,
        conditional: {
          name: "personType",
          value: "PHYSICAL",
        },
      },
      {
        name: "birthDate",
        type: "select",
        label: "Fecha de nacimiento",
        options: [],
        selectType: "single-radio-date-picker-day",
        minDatePickerDate: moment().subtract(100, "years").toDate(),
        maxDatePickerDate: moment().toDate(),
        defaultSelectedKeys: "date",
        validation: smallStringRequired,
        initialValue: "",
        columns: 2,
        conditional: {
          name: "personType",
          value: "PHYSICAL",
        },
        formatInPreConfirmation: (value) => moment(value).format("DD/MM/YYYY"),
      },
      {
        name: "civilStatus",
        type: "select",
        label: "Estado civil",
        placeholder: "Seleccioná una opción",
        validation: radioOrSingleSelectRequired(civilStatusOptions),
        options: civilStatusOptions,
        selectType: "single",
        initialValue: "",
        conditional: {
          name: "personType",
          value: "PHYSICAL",
        },
      },
      {
        name: "spouseName",
        type: "text",
        label: "Nombre/s del cónyuge",
        validation: smallStringRequired,
        initialValue: "",
        columns: 2,
        conditional: {
          name: "civilStatus",
          value: "MARRIED",
        },
      },
      {
        name: "spouseLastName",
        type: "text",
        label: "Apellido/s del cónyuge",
        validation: smallStringRequired,
        initialValue: "",
        columns: 2,
        conditional: {
          name: "civilStatus",
          value: "MARRIED",
        },
      },
      {
        name: "spouseCuil",
        type: "cuit",
        label: "CUIL del cónyuge",
        validation: cuitRequired,
        initialValue: "",
        placeholder: "XX-XXXXXXXX-X",
        conditional: {
          name: "civilStatus",
          value: "MARRIED",
        },
        onBlur: async (values, _, setFieldError) => {
          const value = values.spouseCuil;
          const cuit = values.cuit;

          if (cuit === value && setFieldError) {
            setFieldError("spouseCuil", "Debe ser distinto al tuyo.");
          }
        },
        onChange: async (
          _values,
          setFieldValue,
          _,
          setFieldTouched,
          element
        ) => {
          //If the spouse CUIL value is the same as the user's CUIT, sets an error on the spouse CUIL field.
          const value = element && element.target.value;
          await setFieldValue("spouseCuil", value);
          setFieldTouched && setFieldTouched("spouseCuil", true);
        },
        formatInPreConfirmation: (value) => (value ? asCuit(value) : "-"),
      },
      {
        name: "address.country",
        type: "select",
        label: "País de residencia",
        placeholder: "Seleccioná una opción",
        validation: radioOrSingleSelectRequired(countriesOptions),
        options: countriesOptions,
        selectType: "single",
        initialValue: "",
        onChange: (_, setFieldValue) => {
          const fieldsToClear = {
            "address.fullAddress": "",
            "address.municipality": "",
            "address.stateSelect": "",
            "address.stateText": "",
            "address.state": "",
            "address.zipCode": "",
            "address.floor": "",
            "address.apartment": "",
          };

          Object.entries(fieldsToClear).forEach(([field, value]) => {
            setFieldValue(field, value);
          });
        },
        conditional: {
          name: "personType",
          value: "PHYSICAL",
        },
      },
      {
        name: "address.fullAddress",
        type: "address",
        label: "Domicilio",
        validation: mediumStringRequired,
        initialValue: "",
        autoSelect: "address.fullAddress",
        country: "address.country",
        onChange: (values, setFieldValue) => {
          const zipCode = values.formattedAddress.postalCode;
          const state = values.formattedAddress.state;
          const city = values.formattedAddress.city;
          const municipality = values.formattedAddress.municipality;
          const street = values.formattedAddress.street;
          const number = values.formattedAddress.streetNumber;

          const fieldStateAddresses =
            values.formattedAddress.country === "Argentina"
              ? "address.stateSelect"
              : "address.stateText";
          const parsedStateAddress = parseStateAddress(state);

          setFieldValue("address.zipCode", zipCode);
          setFieldValue(fieldStateAddresses, parsedStateAddress);
          setFieldValue("address.state", parsedStateAddress);
          setFieldValue("address.city", city);
          setFieldValue("address.municipality", municipality);
          setFieldValue("address.street", street);
          setFieldValue("address.number", number);
        },
        autoComplete: "off",
        formatInPreConfirmation: (value) => value?.split(",")?.[0], // Only show street
        conditional: {
          name: "personType",
          value: "PHYSICAL",
        },
      },
      {
        name: "address.floor",
        type: "text",
        label: "Piso",
        validation: smallString,
        initialValue: "",
        columns: 3,
        conditional: {
          name: "personType",
          value: "PHYSICAL",
        },
      },
      {
        name: "address.apartment",
        type: "text",
        label: "Departamento",
        validation: smallString,
        initialValue: "",
        columns: 3,
        conditional: {
          name: "personType",
          value: "PHYSICAL",
        },
      },
      {
        name: "address.zipCode",
        type: "text",
        label: "Código postal",
        validation: zipCodeRequired,
        initialValue: "",
        columns: 3,
        conditional: {
          name: "personType",
          value: "PHYSICAL",
        },
      },
      {
        name: "address.municipality",
        type: "text",
        label: "Localidad",
        validation: smallStringRequired,
        initialValue: "",
        columns: 2,
        conditional: {
          name: "personType",
          value: "PHYSICAL",
        },
      },
      {
        name: "address.stateSelect",
        type: "select",
        label: "Provincia",
        validation: radioOrSingleSelectRequired(cityOptions()),
        initialValue: "",
        options: cityOptions(),
        columns: 2,
        onChange: (value, setFieldValue) => {
          setFieldValue("address.state", value);
        },
        conditional: {
          name: "address.country",
          value: "Argentina",
        },
        dontScrollTo: true,
      },
      {
        name: "address.stateText",
        type: "text",
        label: "Provincia",
        validation: smallStringRequired,
        initialValue: "",
        columns: 2,
        onChange: (value, setFieldValue) => {
          setFieldValue("address.state", value);
        },
        conditional: {
          name: "address.country",
          value: countriesOptions
            .map((country) =>
              country.value.toLowerCase() !== "argentina" ? country.value : undefined
            )
        },
        dontScrollTo: true,
      },
      {
        name: "foreignTaxDetailBool",
        type: "radio",
        label: "¿Tiene residencia fiscal fuera de Argentina?",
        validation: radioOrSingleSelectRequired(yesOrNoBoolOptions),
        options: yesOrNoBoolOptions,
        initialValue: "",
      },
      {
        conditional: {
          name: "foreignTaxDetailBool",
          value: "SI",
        },
        name: "foreignTaxDetail.country",
        type: "select",
        label: "País de residencia fiscal",
        placeholder: "Seleccioná una opción",
        validation: radioOrSingleSelectRequired(countriesOptions),
        options: countriesOptions,
        selectType: "single",
        initialValue: "",
        preconfirmationLabel: "Residencia fiscal",
      },
      {
        conditional: {
          name: "foreignTaxDetailBool",
          value: "SI",
        },
        name: "foreignTaxDetail.idNumber",
        type: "text",
        label: "Identificación fiscal",
        validation: noSpecialCharactersRequired,
        initialValue: "",
      },
      {
        name: "activities",
        type: "select",
        label: "Rubro o actividad",
        placeholder: "Seleccioná las actividades que correspondan",
        validation: multipleSelectRequired(activitiesOptions),
        options: activitiesOptions,
        selectType: "multiple-checkbox",
        initialValue: "",
        showExternalBox: true,
      },
      {
        name: "pep",
        type: "select",
        label: "¿Estás expuesto/a políticamente?",
        tooltip: {
          modal: "modal-PEP",
          label: "¿Qué significa?",
        },
        placeholder: "Seleccioná una opción",
        validation: radioOrSingleSelectRequired(pepOptions),
        options: pepOptions,
        selectType: "single",
        initialValue: "",
        preconfirmationLabel: "¿Expuesto/a políticamente?",
        conditional: {
          name: "personType",
          value: "PHYSICAL",
        },
      },
      {
        conditional: {
          name: "pep",
          value: ["DIRECT", "FAMILY"],
        },
        name: "pepMotive",
        type: "textarea",
        label: "Describí brevemente la situación",
        validation: largeStringRequired,
        initialValue: "",
      },
    ],
    onSave: (values, dispatch, state) => {
      if (!state || !dispatch)
        throw new Error("Error: state or dispatch not found");

      const {
        onboardingData: { formValues },
        collectionData: { activeIndex },
      } = state;

      const shareholders = formValues?.shareholders || [];

      const otherShareholders = shareholders.filter(
        (shareholder: ShareholderData) => shareholder?.taxId !== values.taxId
      );

      const totalOwnership = otherShareholders.reduce(
        (acc: number, shareholder: ShareholderData) =>
          acc + (Number(shareholder?.ownership) || 0),
        0
      );

      if (values.ownership + totalOwnership > 100) {
        showToastMessage("La suma de accionistas no debe superar el 100%.", {
          containerId: "formAlert",
          type: "error",
        });
      } else {
        const newShareholders = [...shareholders];
        newShareholders[activeIndex - 1] = values;
        dispatch(onUpdateFormData({ shareholders: newShareholders }));
      }
    },
  },
});

const stepSeven: Step = {
  step: 7,
  name: "Perfil inversor",
  subtitle: "El riesgo a tomar según el tipo de inversiones.",
  onBack: async (values) => {
    if (!values) throw new Error("Error: values from step seven not found");

    const { businessInformation } = values;
    const { hasJointSignature, corporateType } = businessInformation;

    const parsedHasJointSignature =
      hasJointSignature && hasJointSignature === "SI";

    return {
      backStep: isCompanyWithoutShareholders(corporateType) ? 5 : 6,
      backSubStep: parsedHasJointSignature ? 3 : 2,
    };
  },
};

const stepEight: Step = {
  step: 8,
  name: "Documentación",
  subtitle: "Cargá archivos PDF o DOC de hasta 10mb.",
  fields: [
    {
      name: "businessDocumentation.statute",
      type: "file",
      label: "Estatuto o contrato social escaneado",
      validation: fileRequired(validBusinessDocumentationTypes, maxFileSize),
      initialValue: "",
      maxSize: maxFileSize,
      validTypes: validBusinessDocumentationTypes,
      conditional: {
        name: "businessInformation.corporateType",
        value: [
          translateCorporate.associationFoundationCooperativeChamber,
          translateCorporate.governmentAgencyOrEntity,
          translateCorporate.saCorporation,
          translateCorporate.simplifiedJointStockCompany,
          translateCorporate.reciprocalGuaranteeCompany,
          translateCorporate.llcLimitedLiabilityCompany,
          translateCorporate.others,
        ],
      },
    },
    {
      name: "businessDocumentation.horizontalPropertyRegulationsScanned",
      type: "file",
      label: "Reglamento de propiedad horizontal escaneado",
      validation: fileRequired(validBusinessDocumentationTypes, maxFileSize),
      initialValue: "",
      maxSize: maxFileSize,
      validTypes: validBusinessDocumentationTypes,
      conditional: {
        name: "businessInformation.corporateType",
        value: [translateCorporate.consortium],
      },
    },
    {
      name: "businessDocumentation.trustConstitutionContract",
      type: "file",
      label: "Contrato de constitución del Fideicomiso",
      validation: fileRequired(validBusinessDocumentationTypes, maxFileSize),
      initialValue: "",
      maxSize: maxFileSize,
      validTypes: validBusinessDocumentationTypes,
      conditional: {
        name: "businessInformation.corporateType",
        value: [translateCorporate.trust],
      },
    },
    {
      name: "businessDocumentation.poa",
      type: "file",
      label: "Poder de los firmantes",
      validation: fileNotRequired(validBusinessDocumentationTypes, maxFileSize),
      initialValue: "",
      maxSize: maxFileSize,
      validTypes: validBusinessDocumentationTypes,
      conditional: {
        name: "businessInformation.corporateType",
        value: [
          translateCorporate.saCorporation,
          translateCorporate.llcLimitedLiabilityCompany,
          translateCorporate.associationFoundationCooperativeChamber,
          translateCorporate.trust,
          translateCorporate.simplifiedJointStockCompany,
          translateCorporate.reciprocalGuaranteeCompany,
          translateCorporate.consortium,
          translateCorporate.governmentAgencyOrEntity,
        ],
      },
      tooltip: {
        content:
          "Si el estatuto incluye el poder de los firmantes podés omitir esta documentación.",
      },
    },
    {
      name: "businessDocumentation.balance",
      type: "file",
      label: "Balance certificado por CPCE o doc. patrimonial",
      validation: fileRequired(validBusinessDocumentationTypes, maxFileSize),
      initialValue: "",
      maxSize: maxFileSize,
      validTypes: validBusinessDocumentationTypes,
      conditional: {
        name: "businessInformation.corporateType",
        value: [
          translateCorporate.saCorporation,
          translateCorporate.llcLimitedLiabilityCompany,
          translateCorporate.associationFoundationCooperativeChamber,
          translateCorporate.trust,
          translateCorporate.simplifiedJointStockCompany,
          translateCorporate.reciprocalGuaranteeCompany,
          translateCorporate.consortium,
          translateCorporate.governmentAgencyOrEntity,
        ],
      },
      tooltip: {
        content:
          "Si no tenés el primer balance cerrado adjuntá la documentación patrimonial que demuestre los activos al día de la fecha.",
      },
    },
    {
      name: "businessDocumentation.sharesRegister",
      type: "file",
      label: "Registro de accionistas",
      validation: fileRequired(validBusinessDocumentationTypes, maxFileSize),
      initialValue: "",
      maxSize: maxFileSize,
      validTypes: validBusinessDocumentationTypes,
      conditional: {
        name: "businessInformation.corporateType",
        value: translateCorporate.saCorporation,
      },
      tooltip: {
        content:
          "Si no tenés el registro de acciones adjuntá cualquier documento que acredite la cadena de titularidad o control.",
      },
    },
    {
      name: "businessDocumentation.administratorDesignationConsortium",
      type: "file",
      label: "Acta de designación de administrador",
      validation: fileRequired(validBusinessDocumentationTypes, maxFileSize),
      initialValue: "",
      maxSize: maxFileSize,
      validTypes: validBusinessDocumentationTypes,
      conditional: {
        name: "businessInformation.corporateType",
        value: [translateCorporate.consortium],
      },
    },
    {
      name: "businessDocumentation.administratorDesignationSas",
      type: "file",
      label: "Acta de designación de administrador",
      validation: fileNotRequired(validBusinessDocumentationTypes, maxFileSize),
      initialValue: "",
      maxSize: maxFileSize,
      validTypes: validBusinessDocumentationTypes,
      conditional: {
        name: "businessInformation.corporateType",
        value: [translateCorporate.simplifiedJointStockCompany],
      },
    },
    {
      name: "businessDocumentation.expenseLiquidation",
      type: "file",
      label: "Liquidación de expensas",
      validation: fileRequired(validBusinessDocumentationTypes, maxFileSize),
      initialValue: "",
      maxSize: maxFileSize,
      validTypes: validBusinessDocumentationTypes,
      conditional: {
        name: "businessInformation.corporateType",
        value: [translateCorporate.consortium],
      },
    },
    {
      name: "businessDocumentation.authorities",
      type: "file",
      label: "Acta de designación de autoridades",
      validation: fileRequired(validBusinessDocumentationTypes, maxFileSize),
      initialValue: "",
      maxSize: maxFileSize,
      validTypes: validBusinessDocumentationTypes,
      conditional: {
        name: "businessInformation.corporateType",
        value: [
          translateCorporate.saCorporation,
          translateCorporate.associationFoundationCooperativeChamber,
          translateCorporate.reciprocalGuaranteeCompany,
          translateCorporate.governmentAgencyOrEntity,
          translateCorporate.others,
        ],
      },
    },
    {
      name: "businessDocumentation.uifInscriptionCertificate",
      type: "file",
      label: "Constancia de inscripción ante UIF del fiduciario",
      validation: fileRequired(validBusinessDocumentationTypes, maxFileSize),
      initialValue: "",
      maxSize: maxFileSize,
      validTypes: validBusinessDocumentationTypes,
      conditional: {
        name: "businessInformation.corporateType",
        value: translateCorporate.trust,
      },
    },
    {
      name: "businessDocumentation.worthStatement",
      type: "file",
      label: "Documentación patrimonial",
      validation: fileRequired(validBusinessDocumentationTypes, maxFileSize),
      initialValue: "",
      maxSize: maxFileSize,
      validTypes: validBusinessDocumentationTypes,
      conditional: {
        name: "businessInformation.corporateType",
        value: translateCorporate.others,
      },
    },
    {
      name: "businessDocumentation.partnersNote",
      type: "file",
      label: "Nota de socios protectores y beneficiarios",
      validation: fileRequired(validBusinessDocumentationTypes, maxFileSize),
      initialValue: "",
      maxSize: maxFileSize,
      validTypes: validBusinessDocumentationTypes,
      conditional: {
        name: "businessInformation.corporateType",
        value: translateCorporate.reciprocalGuaranteeCompany,
      },
      tooltip: {
        content:
          "Cargala únicamente si hay socios protectores o beneficiarios con participación menor a 10%.",
      },
      anchor: {
        text: "Ejemplo",
        onClick() {
          const link = document.createElement("a");
          link.href =
            juridicalDocumentLinks["businessDocumentation.partnersNote"];
          link.download = "Nota de socios protectores y beneficiarios.docx";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        },
      },
    },
    {
      name: "businessDocumentation.partnersList",
      type: "file",
      label: "Nómina de socios",
      validation: fileRequired(validBusinessDocumentationTypes, maxFileSize),
      initialValue: "",
      maxSize: maxFileSize,
      validTypes: validBusinessDocumentationTypes,
      conditional: {
        name: "businessInformation.corporateType",
        value: translateCorporate.reciprocalGuaranteeCompany,
      },
    },
    {
      name: "businessDocumentation.membersNote",
      type: "file",
      label: "Nota de miembros",
      validation: fileRequired(validBusinessDocumentationTypes, maxFileSize),
      initialValue: "",
      maxSize: maxFileSize,
      validTypes: validBusinessDocumentationTypes,
      conditional: {
        name: "businessInformation.corporateType",
        value: translateCorporate.associationFoundationCooperativeChamber,
      },
      tooltip: {
        content:
          "Cargala únicamente si hay miembros con participación menor a 10%.",
      },
      anchor: {
        text: "Ejemplo",
        onClick() {
          const link = document.createElement("a");
          link.href =
            juridicalDocumentLinks["businessDocumentation.membersNote"];
          link.download = "Nota de miembros.docx";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        },
      },
    },
    {
      name: "businessDocumentation.trustNote",
      type: "file",
      label: "Nota de fiduciantes, fiduciarios y beneficiarios",
      validation: fileRequired(validBusinessDocumentationTypes, maxFileSize),
      initialValue: "",
      maxSize: maxFileSize,
      validTypes: validBusinessDocumentationTypes,
      conditional: {
        name: "businessInformation.corporateType",
        value: translateCorporate.trust,
      },
      tooltip: {
        content:
          "Cargala únicamente si hay fiduciantes, fiduciarios o beneficiarios con participación menor a 10%.",
      },
      anchor: {
        text: "Ejemplo",
        onClick() {
          const link = document.createElement("a");
          link.href = juridicalDocumentLinks["businessDocumentation.trustNote"];
          link.download =
            "Nota de fiduciantes, fiduciarios y beneficiarios.docx";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        },
      },
    },
    {
      name: "businessDocumentation.shareholdersNote",
      type: "file",
      label: "Nota de accionistas y beneficiarios finales",
      conditionalValidation: (state) => {
        if (!state) throw new Error("Error: state not found");

        const { formValues } = state.onboardingData;
        const shareholders: ShareholderData[] = formValues?.shareholders || [];

        const hasPJShareholder = shareholders.some(
          (shareholder) => shareholder?.personType === "JURIDICAL"
        );

        return hasPJShareholder
          ? fileRequired(validBusinessDocumentationTypes, maxFileSize)
          : fileNotRequired(validBusinessDocumentationTypes, maxFileSize);
      },
      initialValue: "",
      maxSize: maxFileSize,
      validTypes: validBusinessDocumentationTypes,
      conditional: {
        name: "businessInformation.corporateType",
        value: [
          translateCorporate.saCorporation,
          translateCorporate.llcLimitedLiabilityCompany,
          translateCorporate.simplifiedJointStockCompany,
          translateCorporate.others,
        ],
      },
      tooltip: {
        content:
          "En caso de tener accionistas personas jurídicas necesitaremos la nota adjunta, indicando y detallando la cadena accionaria hasta identificar a los los beneficiarios finales personas humanas que posean hasta el 10% de participación directa e indirecta.",
      },
      anchor: {
        text: "Ejemplo",
        onClick() {
          const link = document.createElement("a");
          link.href =
            juridicalDocumentLinks["businessDocumentation.shareholdersNote"];
          link.download = "Nota de accionistas y beneficiarios finales.docx";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        },
      },
    },
    {
      name: "businessDocumentation.other",
      type: "file",
      label: "Otros",
      validation: fileNotRequired(validBusinessDocumentationTypes, maxFileSize),
      initialValue: "",
      maxSize: maxFileSize,
      validTypes: validBusinessDocumentationTypes,
      conditionalTooltip: [
        {
          name: "businessInformation.corporateType",
          value: translateCorporate.consortium,
          tooltip: {
            content: "Nómina de copropietarios",
          },
        },
        {
          name: "businessInformation.corporateType",
          value: [
            translateCorporate.saCorporation,
            translateCorporate.llcLimitedLiabilityCompany,
          ],
          tooltip: {
            content: "Acta de designación de autoridades",
          },
        },
      ],
    },
  ],
  fieldsSections: [
    {
      name: "Completar sólo si el Fiduciario es una Persona Jurídica:",
      fields: [
        {
          name: "businessDocumentation.trusteeDocumentationStatute",
          type: "file",
          label: "Estatuto o contrato social",
          validation: fileNotRequired(
            validBusinessDocumentationTypes,
            maxFileSize
          ),
          initialValue: "",
          maxSize: maxFileSize,
          validTypes: validBusinessDocumentationTypes,
          conditional: {
            name: "businessInformation.corporateType",
            value: translateCorporate.trust,
          },
        },
        {
          name: "businessDocumentation.trusteeDocumentationAuthorities",
          type: "file",
          label: "Acta con designación de autoridades vigentes",
          validation: fileNotRequired(
            validBusinessDocumentationTypes,
            maxFileSize
          ),
          initialValue: "",
          maxSize: maxFileSize,
          validTypes: validBusinessDocumentationTypes,
          conditional: {
            name: "businessInformation.corporateType",
            value: translateCorporate.trust,
          },
        },
      ],
      conditional: {
        name: "businessInformation.corporateType",
        value: translateCorporate.trust,
      },
    },
  ],
  onContinue: async (values: FormikValues, dispatch, state) => {
    if (!state || !dispatch)
      throw new Error("Error: state or dispatch not found");

    const {
      onboardingData: { draftUUID },
    } = state;

    const { businessDocumentation } = values;

    try {
      for (const key in businessDocumentation) {
        const documentType = mapDocumentBusinessType(
          key
        ) as DocumentBusinessType;
        const file = businessDocumentation[key];
        if (file) {
          await sendBusinessDocument({
            draftUUID,
            documentType,
            file,
          });
        }
      }
      dispatch(onUpdateCompletedStep(8));
    } catch (error) {
      console.log("Error step eight: ", error);
      throw new Error("Error al enviar los datos en el paso 8");
    }
  },
};

const infoStep: Step = {
  step: "information-step",
  component: (dispatch, state, formik) => {
    if (!state || !dispatch || !formik)
      throw new Error("Error: state, dispatch or formik not found");

    const { draftUUID, stepData } = state.onboardingData;
    const { type } = state.infoStep;

    const isActiveAccountStep = type === "ACTIVE_ACCOUNT";
    const isPendingRegistryStep =
      type === "PENDING_REGISTRY_REMOTE" || type === "PENDING_REGISTRY_LOCAL";
    const isNullStep = type === null;
    const isRegisteredEmailStep = type === "REGISTERED_EMAIL";

    const isSignatoriesStep = stepData?.step === 5;
    const isIdentityStep = stepData?.step === 3;

    const getNextStepAndSubStep = () => {
      let nextStep = 1;
      let nextSubStep = 1;

      const {
        pep,
        gender,
        businessInformation,
        investmentProfile,
        shareholders,
      } = formik.values;
      const { hasJointSignature, corporateType, registrationAuthority } =
        businessInformation;

      if (type === "PENDING_REGISTRY_LOCAL") {
        const { reachedSteps, lastCompletedSubStep } = stepData;

        if (reachedSteps) {
          nextStep = reachedSteps[reachedSteps.length - 1];
          nextSubStep = lastCompletedSubStep + 1;

          // Signatory step
          if (nextStep === 5 && nextSubStep === 3) {
            if (hasJointSignature === "SI") {
              nextStep = 5;
              nextSubStep = 3;
            } else {
              if (isCompanyWithoutShareholders(corporateType)) {
                nextStep = 7;
              } else {
                nextStep = 6;
                nextSubStep = 1;
              }
            }
          }

          // Send to the last step even if it has been completed
          if (nextStep === 9) nextStep = 8;
          // }
        }
      } else {
        const isFirstSubstepInStepThreeCompleted = gender !== "";
        const isFirstSubstepInStepFourCompleted = pep !== "";
        const isFirstSubstepInStepFiveCompleted = registrationAuthority !== "";
        const isSecondSubstepInStepFiveCompleted = hasJointSignature !== "";
        const isStepSixCompleted = shareholders.length > 0;
        const isStepSevenCompleted = investmentProfile.some(
          (question: string) => investmentProfile[question] !== ""
        );

        // STEP THREE
        if (isFirstSubstepInStepThreeCompleted) {
          nextStep = 3;
          nextSubStep = 2;
        }

        // STEP FOUR
        if (isFirstSubstepInStepFourCompleted) {
          nextStep = 4;
          nextSubStep = 2;
        }

        // STEP FIVE
        if (isFirstSubstepInStepFiveCompleted) {
          nextStep = 5;
          nextSubStep = 2;
        }

        if (isSecondSubstepInStepFiveCompleted) {
          if (hasJointSignature === "SI") {
            nextStep = 5;
            nextSubStep = 3;
          } else {
            if (isCompanyWithoutShareholders(corporateType)) {
              nextStep = 7;
            } else {
              nextStep = 6;
              nextSubStep = 1;
            }
          }
        }

        // STEP SIX
        if (isStepSixCompleted) {
          nextStep = 7;
        }

        // STEP SEVEN
        if (isStepSevenCompleted) {
          nextStep = 8;
        }
      }

      return { nextStep, nextSubStep };
    };

    // Continue
    const onPrimaryClick = () => {
      try {
        if (isActiveAccountStep) {
          dispatch(onUpdateStep(3));
          dispatch(onUpdateReachedStep(3));
          dispatch(onUpdateCompletedStep(2));
        } else if (isPendingRegistryStep) {
          const { nextStep, nextSubStep } = getNextStepAndSubStep();

          dispatch(onUpdateStep(nextStep));
          dispatch(onUpdateSubStep(nextSubStep));
          if (type === "PENDING_REGISTRY_REMOTE") {
            dispatch(onUpdateReachedStep(nextStep));
            dispatch(onUpdateCompletedStep(nextStep - 1));
          }
        } else if (isRegisteredEmailStep) {
          const step = isSignatoriesStep ? 5 : 3;
          dispatch(onUpdateStep(step));
        } else if (isNullStep) {
          document.location.href =
            "https://business-onboarding.allariamas.com.ar/";
        }
      } catch (error) {
        console.log(error);
        showToastMessage(genericToastifyError, {
          containerId: "informationAlert",
          type: "error",
        });
      }
    };

    // Restart or back
    const onSecondaryClick = async () => {
      try {
        if (type === "ACTIVE_ACCOUNT") {
          dispatch(onUpdateStep(1));
        } else if (
          type === "PENDING_REGISTRY_REMOTE" ||
          type === "PENDING_REGISTRY_LOCAL"
        ) {
          const { cuit, email, referredBool, referrer, identityDocument } =
            formik.values;
          const { token, signatureRequestId } = state.authentication;
          const tempValues = {
            token,
            signatureRequestId,
            cuit,
            email,
            referredBool,
            referrer,
            draftUUID,
            identityDocument,
          };

          await deleteIndividualDraft({ draftUUID });

          //Delete dniImages and livenessValidation documents
          const db = getFirestore();
          const docFilesRef = doc(db, "dniImages", tempValues.draftUUID);
          const docLivenessRef = doc(
            db,
            "livenessValidation",
            tempValues.draftUUID
          );

          await deleteDoc(docFilesRef);
          await deleteDoc(docLivenessRef);

          dispatch({ type: "reset-store" });

          if (type === "PENDING_REGISTRY_REMOTE") {
            formik.resetForm({
              values: {
                cuit: tempValues.cuit,
                email: tempValues.email,
                referrer: tempValues.referrer,
                referredBool: tempValues.referredBool,
                identityDocument: tempValues.identityDocument,
              },
            });

            dispatch(onUpdateDraftUUID(tempValues.draftUUID));
            dispatch(updateSignatureRequestId(tempValues.signatureRequestId));
            dispatch(updateToken(tempValues.token));
            dispatch(onUpdateCompletedStep(2));
            dispatch(onUpdateStep(3));
            dispatch(onUpdateReachedStep(3));
          } else if (type === "PENDING_REGISTRY_LOCAL") {
            formik.resetForm();
            dispatch(onUpdateStep(1));
            dispatch(onUpdateReachedStep(1));
          }
        } else if (type === "REGISTERED_EMAIL" && isIdentityStep) {
          dispatch(onUpdateStep(1));
          dispatch(onUpdateReachedStep(1));
        } else if (type === null) {
          dispatch(onUpdateStep(1));
          dispatch(onUpdateReachedStep(1));
        }
      } catch (error) {
        showToastMessage(genericToastifyError, {
          containerId: "informationAlert",
          type: "error",
        });
      }
    };

    let primaryText = "";
    let secondaryText = "";
    let title = "";
    let subtitle = "";
    let description = "";

    if (
      type === "PENDING_REGISTRY_LOCAL" ||
      type === "PENDING_REGISTRY_REMOTE"
    ) {
      primaryText = "Retomar registro";
      secondaryText = "Empezar de nuevo";
      title = "Tenés un registro sin terminar";
      subtitle = "¿Deseás retomar desde donde lo dejaste?";
    } else if (type === "ACTIVE_ACCOUNT") {
      primaryText = "Continuar";
      secondaryText = "";
      title = "¡Un proceso veloz!";
      subtitle = "Agilizamos la apertura precargando tus datos.";
    } else if (type === "REGISTERED_EMAIL") {
      primaryText = isSignatoriesStep
        ? "Modificar datos"
        : isIdentityStep
        ? "Modificar email"
        : "";
      secondaryText = isIdentityStep ? "Verificar DNI" : "";
      title = "Correo electrónico ya registrado";
      subtitle = "El mail ingresado está asociado a otro número de documento.";
      description =
        "Por favor, verificá el DNI ingresado, o modificá el mail para continuar.";
    } else if (type === null) {
      //Error CUIT
      primaryText = "Continuar";
      secondaryText = "Volver";
      title = "El CUIT corresponde a una persona física";
      subtitle = "¿Deseás continuar y registrar una cuenta personal?";
    }

    const primarybutton = {
      text: primaryText,
      onClick: onPrimaryClick,
    };
    const secondarybutton = {
      text: secondaryText,
      onClick: onSecondaryClick,
    };

    return (
      <InteractiveInformation
        primaryButton={primarybutton}
        secondaryButton={secondarybutton}
        title={title}
        subtitle={subtitle}
        description={description}
      />
    );
  },
  hideLayoutButtons: true,
  hideStepper: true,
  showHelpText: (state) => {
    const { type } = state.infoStep;
    return type === "REGISTERED_EMAIL";
  },
};

const onboardingJuridical = async (): Promise<Template> => {
  try {
    const [activities, countries] = await axios.all([
      getActivities(),
      getCountries(),
    ]);

    const countriesTyped = countries as PitbullCountry[];

    const activitiesOptions: Option[] = (
      activities as PitbullActivityJuridical[]
    )?.map(({ activity: { name } }) => {
      return { value: name, label: name };
    });

    const countriesOptions: Option[] = countriesTyped?.map(({ name }) => {
      return { value: name, label: name };
    });

    const citizenshipOptions: Option[] = countriesTyped
      ?.map(({ citizenship }) => {
        return { value: citizenship, label: citizenship };
      })
      .sort((c1: Option, c2: Option) => c1.label.localeCompare(c2.label));

    const questions = await getQuestions();

    const formattedQuestions = questionsToFields(questions);

    const stepSevenWithFields = {
      ...stepSeven,
      fields: formattedQuestions.filter((field) => !!field) as Field[],
      onContinue: async (
        values: FormikValues,
        dispatch: AppDispatch,
        state: RootState
      ) => {
        try {
          if (!state || !dispatch)
            throw new Error("Error: state or dispatch not found");

          // Find the external code of the value (the answer of question)
          // Each question has a externalCode and each answer too, so we need to find the externalCode of the answer about the question
          const findExternalCodeOfValue = (externalCode: string) => {
            const question = questions.find(
              (question) => question.externalCode === externalCode
            );
            return question?.choices.find(
              (choice) =>
                choice.option === values.investmentProfile[externalCode]
            );
          };

          const { email, investmentProfile } = values;

          const choices = Object.keys(investmentProfile);

          const choicesParsed = choices
            .map((choice) => findExternalCodeOfValue(choice)?.externalCode)
            .filter((code): code is string => code !== undefined);

          const {
            onboardingData: { sendedSteps },
          } = state;

          const step = {
            "@type": "5_JURIDICAL",
            investmentProfile: {
              choices: [...choicesParsed],
            },
          };

          //Remove from sendedSteps the step with the same type. Prevents duplicates
          const filteredSendedSteps = sendedSteps.filter(
            (step: StepEndpoint) => step["@type"] !== "5_JURIDICAL"
          );

          const body = {
            companyType: getCompanyData().companyName,
            personType: state.activeTemplate.onboardingType as PersonType,
            email,
            steps: [...filteredSendedSteps, step],
          };
          await sendStep({ stepId: 5, body });

          dispatch(addSendedStep(step));
        } catch (error) {
          console.log(error, "Error at step seven");
          throw new Error("Error al enviar los datos en el paso 7");
        }
      },
    };

    const onboardingSteps = [
      stepOne,
      stepTwo,
      stepThree(countriesTyped),
      stepFour(countriesOptions, activitiesOptions),
      stepFive(countriesOptions, activitiesOptions),
      stepSix(countriesOptions, activitiesOptions, citizenshipOptions),
      stepSevenWithFields,
      stepEight,
      infoStep,
    ];

    const onboardingFields = getOnboardingFields(onboardingSteps);
    const formattedOnboardingFields =
      getPreconfirmationStepFieldsByName(onboardingFields);

    const fieldsNamesInRepresentativePreconfirmationStep = [
      "cuit",
      "name",
      "lastName",
      "citizenship",
      "birthDate",
      "civilStatus",
      "spouseName",
      "spouseLastName",
      "spouseCuil",
      "telephone",
      "representativesAddresses.country",
      "representativesAddresses.fullAddress",
      "representativesAddresses.floor",
      "representativesAddresses.apartment",
      "representativesAddresses.zipCode",
      "representativesAddresses.municipality",
      "representativesAddresses.stateSelect",
      "representativesAddresses.stateText",
      "pep",
      "pepMotive",
    ];

    const representativePreconfirmationStep: PreconfirmationStep = {
      name: "Datos del representante",
      columns: 4,
      fields: [
        ...fieldsNamesInRepresentativePreconfirmationStep.map(
          (field) => formattedOnboardingFields[field]
        ),
      ],
      editStep: {
        step: 4,
        substep: 1,
      },
    };

    const fieldsNamesInBusinessPreconfirmationStep = [
      "businessInformation.legalName",
      "businessInformation.cuit",
      "entityAddress.fullAddress",
      "entityAddress.floor",
      "entityAddress.apartment",
      "entityAddress.zipCode",
      "entityAddress.municipality",
      "entityAddress.state",
      "businessInformation.corporateType",
      "businessInformation.activities",
      "businessInformation.registrationCountry",
      "businessInformation.registrationDate",
      "businessInformation.registrationNumber",
      "businessInformation.registrationAuthority",
      "businessInformation.hasJointSignature",
      "businessInformation.isPublicEntity",
      "businessInformation.isInEconomicGroup",
      "businessInformation.bankAccounts",
    ];

    const businessPreconfirmationStep: PreconfirmationStep = {
      name: "Datos de la entidad",
      columns: 4,
      fields: [
        ...fieldsNamesInBusinessPreconfirmationStep.map(
          (field) => formattedOnboardingFields[field]
        ),
      ],
      editStep: {
        step: 5,
        substep: 1,
      },
    };

    const getFieldsByShareholder = (
      shareholders: ShareholderData[],
      cuitFromRepresentative: string
    ) => {
      const fields =
        stepSix(countriesOptions, activitiesOptions, citizenshipOptions)
          .secondaryForm?.fields || [];
      const excludedFields = ["name", "lastName", "legalName"];

      let shareholdersFields = fields.filter(
        (field) => !excludedFields.includes(field.name)
      );
      const shareholderFieldsWithoutDuplicates = new Set(
        shareholdersFields.map((field) => field.name)
      );
      shareholdersFields = Array.from(shareholderFieldsWithoutDuplicates).map(
        (name) =>
          shareholdersFields.find((field) => field.name === name) as Field
      );

      // Get fields for each shareholder and where to get their values
      return Array.from({ length: shareholders.length }, (_, i) =>
        getFormattedPreconfirmationFields(
          [
            {
              name: "fullName",
              type: "text",
              label: `Accionista ${i + 1}`,
              validation: smallStringRequired,
              initialValue: "",
              columns: 2,
              valueGetter: (values) => {
                return values.personType === "JURIDICAL"
                  ? values.legalName
                  : `${values.name} ${capitalizeString(values.lastName)} ${
                      cuitFromRepresentative === values.cuit ? "(Tú)" : ""
                    }`;
              },
            },
            ...shareholdersFields,
          ],
          shareholders[i]
        )
      );
    };

    const shareholdersPreconfirmationStep = ({
      values,
    }: {
      values: FormikValues;
    }): PreconfirmationStep => {
      const cuitFromRepresentative = values?.cuit || {};
      const shareholders = values?.shareholders || [];

      if (shareholders.length === 0) return {} as PreconfirmationStep;

      return {
        name: `Accionistas (${shareholders.length})`,
        columns: 4,
        fieldsWithSeparator: getFieldsByShareholder(
          shareholders,
          cuitFromRepresentative
        ),
        editStep: {
          step: 6,
        },
      };
    };

    const investmentProfilePreconfirmationStep: PreconfirmationStep = {
      name: "Perfil inversor",
      columns: 2,
      fields: getFormattedPreconfirmationFields(stepSevenWithFields.fields),
      editStep: {
        step: 7,
      },
    };

    const documentationPreconfirmationStep: PreconfirmationStep = {
      name: "Documentación",
      columns: 2,
      fields: getFormattedPreconfirmationFields(stepEight.fields || []),
      editStep: {
        step: 8,
      },
    };

    const preconfirmationSteps = ({
      values,
    }: {
      values?: FormikValues | null;
    }): PreconfirmationStep[] => {
      if (!values) throw new Error("Error: values not found");

      return [
        representativePreconfirmationStep,
        businessPreconfirmationStep,
        shareholdersPreconfirmationStep({ values }),
        investmentProfilePreconfirmationStep,
        documentationPreconfirmationStep,
      ];
    };

    const handleSendTermsAndConditions = async (
      state: RootState,
      values: FormikValues
    ) => {
      try {
        const {
          onboardingData: { sendedSteps, formValues },
          activeTemplate: { onboardingType },
        } = state;

        const stepSix = {
          "@type": "6_JURIDICAL",
          acceptedOptionalTermsAndConditions: values?.senebiAndExteriorNotes,
          acceptedTermsAndConditions: values?.termsAndConditions,
        };

        //Remove from sendedSteps the step with the same type. Prevents duplicates
        const filteredSendedSteps = (stepToFilter: string) =>
          sendedSteps.filter(
            (step: StepEndpoint) => step["@type"] !== stepToFilter
          );

        const baseBody = {
          companyType: getCompanyData().companyName,
          personType: onboardingType as PersonType,
          email: formValues?.email,
        };

        await sendStep({
          stepId: 6,
          body: {
            ...baseBody,
            steps: [...filteredSendedSteps("6_JURIDICAL"), stepSix],
          },
        });

        await sendStep({
          stepId: 7,
          body: {
            ...baseBody,
            steps: [
              ...filteredSendedSteps("7_JURIDICAL"),
              stepSix,
              {
                "@type": "7_JURIDICAL",
                representatives: [],
              },
            ],
          },
        });
      } catch (error) {
        console.log(error, "error");
      }
    };

    const { favicon, formTitle, logo } = getCompanyData();

    return {
      onboardingType: "JURIDICAL",
      formTitle,
      logo,
      icon: juridicalIcon,
      favicon,
      steps: onboardingSteps,
      preconfirmation: ({ values }) => ({
        steps: preconfirmationSteps({ values }).filter(
          Boolean
        ) as PreconfirmationStep[],
        checkboxes: getCompanyData().termsAndConditions.map((term) => ({
          ...term,
          initialValue: false,
          validation: term.required ? checkboxRequired : checkbox,
        })),
        sendTermsAndConditions: handleSendTermsAndConditions,
      }),
    };
  } catch (error) {
    throw new Error(error as string);
  }
};

export default onboardingJuridical;
